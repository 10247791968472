<template>
  <div>
    <q-item-label header>{{ lang == "pt" ? "Overview" : "Overview" }}</q-item-label>
    <q-item clickable @click="router.push('/quickguide')">
      <q-item-section>
        <q-item-label lines="1">{{ lang == "pt" ? "Guia rápido" : "Quickguide" }}</q-item-label>
      </q-item-section>
      <!-- <q-item-section avatar>
        <q-icon color="grey" name="article" />
      </q-item-section> -->
    </q-item>

    <q-item clickable @click="router.push('/authentication')">
      <q-item-section>
        <q-item-label lines="1">{{
          lang == "pt" ? "Autenticação" : "Authentication Docs"
        }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-item-label header>{{ lang == "pt" ? "Documentação de APIs" : "API Docs" }}</q-item-label>

    <q-item clickable @click="router.push('/docs-patients')">
      <q-item-section>
        <q-item-label lines="1">{{ lang == "pt" ? "Pacientes" : "Patients" }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="router.push('/docs-chamada-digital')">
      <q-item-section>
        <q-item-label lines="1">{{
          lang == "pt" ? "Chamada Digital" : "Chamada Digital"
        }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-item-label header>{{
      lang == "pt" ? "Documentação de APIs Onprem" : "API Docs Onprem"
    }}</q-item-label>

    <q-item clickable @click="router.push('/docs-appoiments-onprem')">
      <q-item-section>
        <q-item-label lines="1">{{ lang == "pt" ? "Agenda" : "Appoiments" }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="router.push('/docs-exams-results-onprem')">
      <q-item-section>
        <q-item-label lines="1">{{
          lang == "pt" ? "Resultado de Exames" : "Exams Results"
        }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="router.push('/docs-checagem-oncologica')">
      <q-item-section>
        <q-item-label lines="1">{{
          lang == "pt" ? "Checagem Oncológica" : "Oncology Checking"
        }}</q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

defineProps({
  lang: String,
});
</script>

<style lang="scss"></style>
