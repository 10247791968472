<template>
  <div style="overflow-y: auto; height: 100vh; padding: 20px">
    <div v-if="source" v-html="markdown.render(source)" class="markdown-body" />
  </div>
</template>

<script setup>
import MarkdownIt from "markdown-it"
import MarkdownItAbbr from "markdown-it-abbr";
import MarkdownItAnchor from "markdown-it-anchor";
import MarkdownItFootnote from "markdown-it-footnote";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import MarkdownItSub from "markdown-it-sub";
import MarkdownItSup from "markdown-it-sup";
import MarkdownItTasklists from "markdown-it-task-lists";
import MarkdownItTOC from "markdown-it-toc-done-right";
import { defineProps, onMounted, ref, watch } from "vue";

const props = defineProps({
  markdownFile: String
})

const markdown = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
})
  .use(MarkdownItAbbr)
  .use(MarkdownItAnchor)
  .use(MarkdownItFootnote)
  .use(MarkdownItHighlightjs)
  .use(MarkdownItSub)
  .use(MarkdownItSup)
  .use(MarkdownItTasklists)
  .use(MarkdownItTOC);

const source = ref()

function getSourceMd() {
  if (!props.markdownFile) return;
  var client = new XMLHttpRequest();
  client.open('GET', props.markdownFile);
  client.onreadystatechange = function () {
    if (props.markdownFile && client.responseText)
      source.value = client.responseText;
  }
  client.send();
}

onMounted(getSourceMd);
watch(props, getSourceMd);
</script>