import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";
import router from "./router";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "spdata-design-system-components/dist/spdata-design-system-components.css";
import 'highlight.js/styles/default.css';
import "github-markdown-css/github-markdown-light.css";

const vueLifecycles = singleSpaVue({
  createApp,
  handleInstance: (app) => {
    app.use(Quasar, quasarUserOptions);
    app.use(router);
  },
  appOptions: {
    render() {
      return h(App, {});
    },
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
