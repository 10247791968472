<template>
  <q-layout view="hHh lpR fFf">
    <q-drawer show-if-above v-model="menuAppearing" :mini="leftDrawerOpen" side="left" bordered>
      <div style="display: flex; align-items: center;">
        <q-btn dense flat round icon="menu" style="margin: 8px; font-size: 16px"
          @click="leftDrawerOpen = !leftDrawerOpen">
        </q-btn>
        <q-img height="40px" fit="contain" src="https://cdn.spdata.com.br/imagens/logos/logo_spdata.webp" />
        <div style="width: 100px; font-weight: bold; color: #00629d;">
          DOCS
        </div>
      </div>

      <q-list class="rounded-borders" style="max-width: 350px">
        <q-separator />

        <div style="padding: 20px">
          <ButtonSwitch v-model="lang" spread class="my-custom-toggle" no-caps rounded unelevated toggle-color="primary"
            color="white" text-color="primary" :options="[
              { label: 'Português', value: 'pt' },
              { label: 'English', value: 'en' }
            ]" />
        </div>

        <DevelopersMenu v-if="route.path.startsWith('/developers')" :lang="lang" />
        <DocsMenu v-else :lang="lang" />
      </q-list>
    </q-drawer>

    <q-page-container style="height: 100vh; overflow-y: hidden;">
      <div id="docs"></div>

      <router-view :lang="lang" />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import DocsMenu from "./components/docs/menu.vue";
import DevelopersMenu from "./components/developers/menu.vue";
import { ButtonSwitch } from "spdata-design-system-components";

const route = useRoute();

const lang = ref('pt')
const leftDrawerOpen = ref(false);
const menuAppearing = ref(false);
</script>

<style lang="scss"></style>
