<template>
  <MarkdownRenderer v-if="lang" :markdownFile="markdownFile" />
</template>

<script setup>
import { defineProps, watch, ref, onMounted } from "vue"
import MarkdownRenderer from "../../components/markdown-renderer.vue";

const props = defineProps({
  lang: String
})

const markdownFile = ref()

function buildMarkdown() {
  markdownFile.value = `/markdowns/authentication-${props.lang}.md`;
}

onMounted(buildMarkdown);
watch(props, buildMarkdown);
</script>