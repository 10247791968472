import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home-view.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'HomeView',
		component: HomeView
	},
	// {
	//   path: '/developers/api/identity',
	//   name: 'IdentityDocs',
	//   component: () => import("../views/developers/identity-docs.vue"),
	// },
	// {
	//   path: '/developers/api/individuals',
	//   name: 'IndividualsDocs',
	//   component: () => import("../views/developers/individuals-docs.vue"),
	// },
	// {
	//   path: '/developers/api/ehr',
	//   name: 'EhrDocs',
	//   component: () => import("../views/developers/ehr-docs.vue"),
	// },
	// {
	//   path: '/developers/api/to-do/patients',
	//   name: 'TodoPatientsDocs',
	//   component: () => import("../views/developers/to-do/patients-docs.vue"),
	// },
	// {
	//   path: '/developers/api/to-do/advance-sign',
	//   name: 'TodoAdvanceSignDocs',
	//   component: () => import("../views/developers/to-do/advance-sign-docs.vue"),
	// },
	{
		path: '/quickguide',
		name: 'QuickguideDocs',
		component: () => import("../views/docs/quickguide-docs.vue"),
	},
	{
		path: '/authentication',
		name: 'AuthDocs',
		component: () => import("../views/docs/authentication-docs.vue"),
	},
	{
		path: '/docs-patients',
		name: 'DocsPatients',
		component: () => import("../views/docs/api-docs/patients.vue"),
	},
	{
		path: '/docs-compositions',
		name: 'DocsCompositions',
		component: () => import("../views/docs/api-docs/compositions.vue"),
	},
	{
		path: '/docs-exams-results-onprem',
		name: 'DocsExamsResultsOnprem',
		component: () => import("../views/docs/api-docs/exam-results-onprem.vue"),
	},
	{
		path: '/docs-appoiments-onprem',
		name: 'DocsAppoimentsOnprem',
		component: () => import("../views/docs/api-docs/appoiments-onprem.vue"),
	},
	{
		path: '/docs-chamada-digital',
		name: 'DocsChamadaDigital',
		component: () => import("../views/docs/api-docs/chamada-digital.vue"),
	},
	{
		path: '/docs-checagem-oncologica',
		name: 'DocsChecagemOncologica',
		component: () => import("../views/docs/api-docs/app-checagem-oncologica.vue"),
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
