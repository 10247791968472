<template>
  <div>
    <q-item-label header>Digital 4Health APIs {{ lang == "pt" ? "publicadas" : "published" }}</q-item-label>
    <q-item clickable @click="router.push('/developers/api/identity')">
      <q-item-section avatar top>
        <q-avatar icon="admin_panel_settings" color="grey" text-color="white" />
      </q-item-section>

      <q-item-section>
        <q-item-label lines="1">Identity API</q-item-label>
        <q-item-label caption>User management API</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="router.push('/developers/api/individuals')">
      <q-item-section avatar top>
        <q-avatar icon="person" color="grey" text-color="white" />
      </q-item-section>

      <q-item-section>
        <q-item-label lines="1">Individuals API</q-item-label>
        <q-item-label caption>Persons, Patients and Practitioners API</q-item-label>
      </q-item-section>
    </q-item>

    <!-- <q-item clickable @click="router.push('/developers/api/ehr')">
      <q-item-section avatar top>
        <q-avatar icon="local_hospital" color="grey" text-color="white" />
      </q-item-section>

      <q-item-section>
        <q-item-label lines="1">EHR API</q-item-label>
        <q-item-label caption>Electronic Health Record API</q-item-label>
      </q-item-section>
    </q-item> -->

    <q-item-label header>{{ lang == "pt" ? "Documentação de novas APIs (to do)" : "To do APIs documentation"
    }}
    </q-item-label>

    <q-item clickable @click="router.push('/developers/api/to-do/patients')">
      <q-item-section>
        <q-item-label lines="1">Patients API</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="router.push('/developers/api/to-do/advance-sign')">
      <q-item-section>
        <q-item-label lines="1">Advance Sign API</q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

defineProps({
  lang: String
})
</script>

<style lang="scss"></style>
