<template>
  <iframe v-if="props.lang" :src="redocFile" style="width: 100%; height: 100%; border: none"></iframe>
</template>

<script setup>
import { defineProps, watch, ref, onMounted } from "vue";

const props = defineProps({
  lang: String,
});

const redocFile = ref();

function buildRedocFilePath() {
  redocFile.value = `/api-docs/exams-results-onprem-${props.lang}.html`;
}

onMounted(buildRedocFilePath);
watch(props, buildRedocFilePath);
</script>
